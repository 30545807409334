<template>
  <div class="userInfo">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 账号安全</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="tab">
        <h1>2分钟急速创建简历，马上找到心仪工作</h1>
        <ul class="ul">
          <li class="active">
            <img src="~/static/login/01_active.png" alt="">
            <h3>基本信息</h3>
          </li>
          <li class="active">
            <img src="~/static/login/02_active.png" alt="">
            <h3>工作/实习经历</h3>
          </li>
          <li>
            <img src="~/static/login/03.png" alt="">
            <h3>求职意向</h3>
          </li>
        </ul>
      </div>
      <el-form :model="form" :rules="error" label-width="120px" ref="form" class="form">
        <div class="jl3" v-if="indexLength == 0">
          <a href="javascript:void(0);" class="jl4" @click="noWorks()">暂无工作经验→</a>
        </div>
        <div class="form_title">
          <h2>工作/实习经历</h2>
          <p>请填写最近一段工作经验，企业会很关注哦</p>
        </div>
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请输入公司全称" class="wrap_input w336"></el-input>
        </el-form-item>
        <el-form-item label="所属行业" prop="hyTop">
          <ThreeLink
              :codeType="'hy1'"
              :codeLevel="'2'"
              placeholder="请选择所属行业"
              class="w336 wrap_input"
              @setMValue="setScasValue($event,form,['hyTop','hy'])"
              :defaultValue="[form.hyTop,form.hy]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="职位名称" prop="jobName">
          <el-input v-model="form.jobName" placeholder="请输入职位名称" class="wrap_input w336"></el-input>
        </el-form-item>
<!--        <el-form-item label="薪资待遇" prop="jobSalary">-->
<!--          <MulSelect-->
<!--              :code="'user_salary'"-->
<!--              class="w336 wrap_input"-->
<!--              placeholder="请选择薪资范围"-->
<!--              @setMValue="setSelectValue($event,'jobSalary')"-->
<!--              :defaultValue="form.jobSalary"-->
<!--          ></MulSelect>-->
<!--        </el-form-item>-->
        <el-form-item label="在职时间" prop="beginTime">
          <el-date-picker
              v-model="form.beginTime"
              type="month"
              value-format="yyyy/MM"
              class="w180 wrap_input"
              placeholder="请选择开始时间"
              popper-class="eagle-a11y-uncut"
          ></el-date-picker>
          <span class="paddL10 paddR10">~</span>
          <el-date-picker
              v-model="form.endTime"
              type="month"
              value-format="yyyy/MM"
              class="w180 wrap_input"
              placeholder="请选择结束时间"
              :disabled="disabled"
              @change="onTimes"
              popper-class="eagle-a11y-uncut"
          ></el-date-picker>
          <el-checkbox v-model="checkbox" class="marL10" @change="onChange">至今</el-checkbox>
        </el-form-item>
        <el-form-item label="工作描述" prop="jobContent">
          <el-input type="textarea"
                    placeholder="请输入内容"
                    v-model="form.jobContent"
                    maxlength="150"
                    rows="4"
                    show-word-limit
                    class="wrap_input w430"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="w336" @click="handleSave('form')">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import MulSelect from "@/components/selectComponents/MulSelect";
import ThreeLink from "@/components/selectComponents/ThreeLink";

export default {
  name: "userWork",
  components: {
    MulSelect,
    ThreeLink
  },
  data() {
    return {
      form: {
        companyName: '',
        hyTop: '',
        hy: '',
        jobName: '',
        beginTime: '',
        endTime: '',
        jobContent: '',
        noWork: '0',
      },
      error: {
        companyName: [
          {required: true, message: "公司名称不能为空", trigger: "blur"},
        ],
        hyTop: [
          {required: true, message: "行业筛选不能为空", trigger: "change"},
        ],
        jobName: [
          {required: true, message: "职位不能为空", trigger: "blur"},
        ],
        beginTime: [
          {required: true, message: "在职时间不能为空", trigger: "change"},
        ],
        jobContent: [
          {required: true, message: "工作描述不能为空", trigger: "blur"},
        ],
      },
      endTime: '',
      checkbox: false,
      disabled: false,
      indexLength:0,
    }
  },
  methods: {
    async getInfo() {
      let res = await this.$api.myResumeWorkListApi();
      if (res.data.success) {
        if(res.data.data){
          this.indexLength = res.data.data.length;
          new Promise((resolve,reject) =>{
            if(this.indexLength > 0){
              resolve(this.form = res.data.data[0]);
               if(!this.form.endTime){
                  this.checkbox = true;
                  this.disabled = true;
                }
            }else{
              reject('暂无工作经验');
            }
          })

        }
      }
    },
    // 提交表单
    handleSave(form) {
      this.$refs[form].validate(async (valid) => {
        // this.form.endTime = this.endTime;
        try {
          if (valid) {
            let res = await this.$api.resumeWork(this.form);
            if (res.data.success) {
              this.$message.success(res.data.msg);
              this.$router.push('/userLogin/userApply');
            } else {
              this.$message.error(res.data.msg);
            }
          } else {
            this.$message.error('信息不完整！');
            return false;
          }
        } catch (err) {
          alert(err);
        }
      });
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    async noWorks(){
      this.form.noWork = '1';
      let res = await this.$api.resumeWork(this.form);
      if(res.data.success){
        this.$router.push('/userLogin/userApply');
      }
    },
    setSelectValue(val, key) {
      this.form[key] = val;
    },
    onTimes(val) {
      this.endTime = val;
    },
    onChange(val) {
      if (val) {
        this.disabled = true;
        this.form.endTime = new Date();
      } else {
        this.disabled = false;
      }
    },
  },
  created() {
    this.getInfo();
  }
}
</script>

<style scoped lang="less">
.userInfo {
  width: 100%;
  min-height: 100vh;
  background: white;

  .w336 {
    width: 336px;
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28A46D;
      }

      a {
        font-size: 18px;
        color: #66716C;
      }

      a:hover {
        color: #28A46D;
      }
    }

    .tab {
      width: 635px;
      margin: 0 auto;
      padding-top: 60px;


      h1 {
        font-size: 28px;
        padding-bottom: 36px;
        text-align: center;
      }

      .ul {
        display: flex;

        li {
          position: relative;
          flex: 1;
          text-align: center;

          h3 {
            font-size: 18px;
            margin-top: 6px;
            color: #66716c;
          }
        }

        li.active h3 {
          color: #28A46D;
        }

        li::after {
          content: '';
          position: absolute;
          top: 50%;
          left: -50px;
          transform: translateY(-50%);
          width: 102px;
          height: 2px;
          background: #CCD0CE;
        }

        li.active::after {
          background: #28A46D;
        }

        li:first-child::after {
          display: none;
        }
      }
    }

    .form {
      position: relative;
      width: 792px;
      margin: 26px auto 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 36px 109px;

      .jl3 {
        position: absolute;
        right: 3%;
        font-size: 17px;
        letter-spacing: 2px;
      }

      .jl4 {
        color: red;
      }

      .form_title {
        text-align: center;
        padding-bottom: 30px;

        h2 {
          font-size: 20px;
          margin-bottom: 6px;
        }

        p {
          font-size: 16px;
          color: #66716C;
        }
      }

      /deep/ .el-form-item {
        .el-textarea {
          .el-input__count {
            background: transparent;
          }
        }

        .el-input__inner::placeholder, .el-textarea__inner::placeholder {
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
}
</style>
